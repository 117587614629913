import React, { FunctionComponent } from 'react';
import { SubMenuProps } from './typings';
import { View, Text } from '@upstox/upstox-ui';
import { GTM_EVENTS } from 'src/constants';
import { registerGTMEvent } from 'utils/events';

const MenuTitle: FunctionComponent<SubMenuProps> = ({
  subMenuTitle,
  subMenuItems,
  rel,
  mainUrl,
  section,
  allowGtmEvents = true,
}) => {
  return (
    <>
      {subMenuTitle && (
        <View className={'r-title'} alignItems="center">
          <a href={mainUrl ?? '#'} target="_self">
            <Text
              color="text.white"
              variant="bodyBold"
              size={12}
              lineHeight={35}
              onClick={() => {
                allowGtmEvents &&
                  registerGTMEvent({
                    event: GTM_EVENTS.TOP_NAVIGATION_INTERACTION,
                    cta_text: subMenuTitle,
                    section: section,
                    section_name: 'NA',
                  });
              }}
            >
              {subMenuTitle}
            </Text>
          </a>
        </View>
      )}
      <View as="ul" flexDirection="column">
        {subMenuItems.map((item, index) => {
          return (
            <View as="li" key={index}>
              <a href={item.link} target={item.target} rel={rel}>
                <Text
                  color="text.white"
                  variant="body"
                  size={16}
                  style={{ lineHeight: '150%', letterSpacing: '-0.14px' }}
                  onClick={() => {
                    registerGTMEvent({
                      event: GTM_EVENTS.TOP_NAVIGATION_INTERACTION,
                      cta_text: item.text,
                      header_text: subMenuTitle || section,
                      section_name: section,
                    });
                  }}
                >
                  {item.text}
                </Text>
              </a>
            </View>
          );
        })}
      </View>
    </>
  );
};

export default MenuTitle;
