import {
  BLOCKED_SEGMENTS,
  BSE_BLOCKED_INSTRUMENT_TYPES,
  NSE_ALLOWED_INSTRUMENT_TYPES,
} from './constants';
import {
  InstrumentType,
  Segments,
  ScripItem,
  optionChainInstrumentType,
  SearchTabKeys,
  ScripItemType,
} from './Types';

export function isMF(scripData: ScripItem) {
  return scripData.segment === Segments.MF;
}

export function isFnO(scripData: ScripItem) {
  return scripData.segment === Segments.NSE_FO;
}

export function isFutures(scripData: ScripItem) {
  return scripData.instrumentType === InstrumentType.FUT;
}

export function isOptions(scripData: ScripItem) {
  return (
    scripData.instrumentType === InstrumentType.CE ||
    scripData.instrumentType === InstrumentType.PE
  );
}

export function isEquity(scripData: ScripItem) {
  return (
    scripData.segment === Segments.NSE_EQ ||
    scripData.segment === Segments.BSE_EQ
  );
}

export function isIndex(scripData: ScripItem) {
  return (
    scripData.segment === Segments.NSE_INDEX ||
    scripData.segment === Segments.BSE_INDEX
  );
}

export function createSlugFromScripName(scripName: string) {
  const regexSteps: Array<[RegExp, string]> = [
    [/[^A-Za-z0-9/]/g, '-'], // replace all non alphabets with space
    [/[-]+/g, '-'], // replace all multiple dashes with single dashes --- -> -
    [/-$/g, ''], // remove trailing dash
  ];

  let slug = scripName.toLowerCase();
  regexSteps.forEach((regex) => {
    slug = slug.replace(...regex);
  });

  return slug;
}

export function createScripPageUrl(scripData: ScripItem) {
  const urlSuffix = {
    stocks: '-share-price',
    indices: '-share-price',
    'options-live': '-call-put-option-price',
    'futures-live': '-future-share-price',
  };
  const baseURL = `${location.protocol}//${location.host}`;
  if (scripData?.instrumentType === optionChainInstrumentType) {
    return `${baseURL}/option-chain/${createSlugFromScripName(scripData.name)}`;
  } else if (isIndex(scripData)) {
    return `${baseURL}/indices/${createSlugFromScripName(scripData.name)}${
      urlSuffix['indices']
    }/`;
  } else if (isEquity(scripData)) {
    return `${baseURL}/stocks/${createSlugFromScripName(scripData.name)}${
      urlSuffix['stocks']
    }/${scripData.isin}/`;
  } else if (isFnO(scripData)) {
    const slugInput =
      scripData.assetType === 'INDEX' ? scripData.assetSymbol : scripData.name;
    if (isFutures(scripData)) {
      const expiry = scripData.expiry.toUpperCase().split(' ').join('');
      return `${baseURL}/futures-live/${createSlugFromScripName(slugInput)}${
        urlSuffix['futures-live']
      }/${expiry}/`;
    } else if (isOptions(scripData)) {
      const expiry = scripData.expiry
        .slice(0, -3)
        .toUpperCase()
        .split(' ')
        .join('');
      const lastUrlSegment = `${expiry}${scripData.strikePrice}${scripData.instrumentType}`;
      return `${baseURL}/options-live/${createSlugFromScripName(slugInput)}${
        urlSuffix['options-live']
      }/${lastUrlSegment}/`;
    }
  } else if (isMF(scripData)) {
    return `${baseURL}/mutual-funds/${scripData.schemeName
      .replace(/[^a-zA-Z0-9]+/gi, '-')
      .toLowerCase()}-${scripData.upstoxSchemeId}`;
  }
}

export function filterSearchResults(searchList: ScripItem[]) {
  const isinMap: { [key in string]: number } = {};
  return searchList
    ?.filter((scrip) => {
      if (BLOCKED_SEGMENTS.includes(scrip.segment)) {
        return;
      }
      if (
        (scrip.segment === Segments.NSE_EQ ||
          scrip.segment === Segments.NSE_FO) &&
        !NSE_ALLOWED_INSTRUMENT_TYPES.includes(scrip.instrumentType)
      ) {
        return;
      }
      if (
        scrip.segment === Segments.BSE_EQ &&
        (BSE_BLOCKED_INSTRUMENT_TYPES.includes(scrip.instrumentType) ||
          scrip.name.includes('MUTUAL'))
      ) {
        return;
      }
      if (
        scrip.segment === Segments.BSE_EQ ||
        scrip.segment === Segments.NSE_EQ
      ) {
        isinMap[scrip.isin] = (isinMap[scrip.isin] || 0) + 1;
      }
      return true;
    })
    .filter((scrip) => {
      if (isEquity(scrip)) {
        // remove BSE scrips if nse is present
        if (isinMap[scrip.isin] >= 2 && scrip.segment === Segments.BSE_EQ) {
          return false;
        }
      }
      return true;
    });
}

export const getSegmentFromKey = (key: string) => {
  if (key && (key === SearchTabKeys.ALL || key === SearchTabKeys.FO)) {
    return `${key},${ScripItemType.OPTION_CHAIN}`;
  }
  return key;
};

export const getListDisplayItems = (item: ScripItem) => {
  const segment = item.segment;
  let displayName = item.name;
  let displaySymbol = item.tradingSymbol;
  let displayType = '';

  switch (segment) {
    case Segments.NSE_EQ:
    case Segments.BSE_EQ:
      displayName = displayName.toLowerCase();
      displayType = 'Stock';
      break;

    case Segments.NSE_FO:
    case Segments.BSE_EQ:
      displayName = item.tradingSymbol;
      displaySymbol = '';
      displayType = 'F&O';
      break;

    case Segments.MF:
      displayName = item.schemeLegalName;
      displaySymbol = `${
        item.distributionFrequency ? item.distributionFrequency + ' -' : ''
      } ${item.schemeClass}`.toLowerCase();
      displayType = 'MF';
      break;

    case Segments.NSE_INDEX:
    case Segments.BSE_INDEX:
      displayName = displayName.toLowerCase();
      displayType = 'Index';
      displaySymbol = '';
      break;
  }

  if (item?.instrumentType === optionChainInstrumentType)
    displayName = `${displayName || item?.name} Option Chain`;

  return { displayName, displayType, displaySymbol };
};
