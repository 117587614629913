import {
  PRO_WEB_URL,
  PRO_MOBILE_URL,
  MUTUAL_FUNDS_URL,
  IPOS_URL,
  FUTURES_AND_OPTIONS_URL,
  STOCKS_URL,
  INDICES_URL,
  UPSTOX_X_TRADING_VIEW_URL,
  DEMAT_ACCOUNT_URL,
  TRADING_ACCOUNT_URL,
  COMMODITY_TRADING_URL,
  EQUITY_TRADING_URL,
  DOWNLOAD_FORMS_URL,
  GLOSSARY_URL,
  HELP_CENTER_URL,
  ABOUT_US_URL,
  MARKET_TALK_URL,
  BROKERAGE_CHARGES_URL,
  REFER_AND_EARN_URL,
  ANNOUNCEMENTS_URL,
  PARTNER_URL,
  PRESS_RELEASE_URL,
  BUG_BOUNTY_URL,
  MEDIA_KIT_URL,
  TRADE_PRICE_CHECKER_URL,
  CONTACT_US_URL,
  CHART_360,
  UPLINK_DEVELOPER_URL,
  OPEN_MUTUAL_FUNDS_URL,
} from '../../Models/Actions/Footer/url-helper';

export const productsData = [
  {
    text: 'Pro Web',
    link: PRO_WEB_URL,
  },
  {
    text: 'Mutual Funds',
    link: MUTUAL_FUNDS_URL,
  },
  {
    text: 'Futures And Options',
    link: FUTURES_AND_OPTIONS_URL,
  },
  {
    text: 'Indices',
    link: INDICES_URL,
  },
  {
    text: 'Upstox x TradingView',
    link: UPSTOX_X_TRADING_VIEW_URL,
  },
  {
    text: 'Chart 360',
    link: CHART_360,
  },
  {
    text: 'Pro Mobile',
    link: PRO_MOBILE_URL,
  },

  {
    text: 'IPOs',
    link: IPOS_URL,
  },
  {
    text: 'Stocks',
    link: STOCKS_URL,
  },

  {
    text: 'Uplink Developer API',
    link: UPLINK_DEVELOPER_URL,
  },

  {
    text: 'Trade Price Checker',
    link: TRADE_PRICE_CHECKER_URL,
  },
];

export const servicesData = [
  {
    link: DEMAT_ACCOUNT_URL,
    text: 'Demat Account',
  },
  {
    link: TRADING_ACCOUNT_URL,
    text: 'Trading Account',
  },
  {
    link: OPEN_MUTUAL_FUNDS_URL,
    text: 'Open Mutual Fund Account',
  },
  {
    link: EQUITY_TRADING_URL,
    text: 'Equity Trading',
  },
  {
    link: COMMODITY_TRADING_URL,
    text: 'Commodity Trading',
  },
  {
    link: DOWNLOAD_FORMS_URL,
    text: 'Download Forms',
  },
];

export const companyData = [
  {
    text: 'About Us',
    link: ABOUT_US_URL,
  },
  {
    text: 'Brokerage Charges',
    link: BROKERAGE_CHARGES_URL,
  },

  {
    text: 'Press Releases',
    link: PRESS_RELEASE_URL,
  },
  {
    text: 'Bug Bounty Program',
    link: BUG_BOUNTY_URL,
  },
  {
    text: 'Contact Us',
    link: CONTACT_US_URL,
  },
  {
    link: HELP_CENTER_URL,
    text: 'Help Center',
  },
  {
    text: 'Market Talk',
    link: MARKET_TALK_URL,
  },
  {
    text: 'Announcements',
    link: ANNOUNCEMENTS_URL,
  },

  {
    text: 'Media Kit',
    link: MEDIA_KIT_URL,
  },

  {
    text: 'Partner with us',
    link: PARTNER_URL,
  },
  {
    text: 'Refer and Earn',
    link: REFER_AND_EARN_URL,
  },

  {
    link: GLOSSARY_URL,
    text: 'Glossary',
  },
];

export const productServicesMobileData = [
  {
    key: 'products',
    label: 'Products',
    links: productsData,
  },
  {
    label: 'Services',
    key: 'services',
    links: servicesData,
  },
  {
    label: 'Upstox',
    key: 'upstox',
    links: companyData,
  },
];
