import React from 'react';
import styles from './styles.module.scss';
import InfoLinks from '../InfoLinks/InfoLinks';
import {
  companyData,
  productsData,
  productServicesMobileData,
  servicesData,
} from './constants';
import DoubleColLinks from '../DoubleColLinks/DoubleColLinks';
import Collapse from '@ui-ipo-common/components/Collapse';
import { useWindowSize } from 'src/common/hooks/useWindowSize';
import { View } from '@upstox/upstox-ui';

const ProductAndServices: React.FC = () => {
  const [collapse, setCollapse] = React.useState(false);

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      {!isMobile && (
        <div
          className={collapse ? styles.container : styles.collapsedContainer}
        >
          <InfoLinks
            title="Products"
            links={productsData}
            open={!collapse}
            handleToggleCollapse={toggleCollapse}
          />
          <InfoLinks
            title="Services"
            links={servicesData}
            open={!collapse}
            handleToggleCollapse={toggleCollapse}
          />
          <InfoLinks
            title="Upstox"
            links={companyData}
            open={!collapse}
            handleToggleCollapse={toggleCollapse}
          />
        </div>
      )}
      {isMobile && (
        <View
          flexDirection="column"
          className={`faqWrapper ${styles.mobileView}`}
        >
          {productServicesMobileData.map((link, index) => (
            <Collapse
              titleTextColor="white"
              title={link.label}
              key={link.key}
              className="collapseContainer"
              headerClassName="f-header"
              bodyClassName="body"
              iconType="arrow"
              iconColor="white"
            >
              <DoubleColLinks links={link?.links} title={link.label} />
            </Collapse>
          ))}
        </View>
      )}
    </>
  );
};

export default ProductAndServices;
