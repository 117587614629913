import { Provider } from 'mobx-react';
import '../../styles/globals.scss';
import './page.scss';
import type { AppProps } from 'next/app';
import FelaProvider from 'src/common/providers/FelaProvider';
import { IRenderer } from 'fela';
import { HideCursorFocus } from '@upstox/upstox-ui';
import { useIPOStore } from 'src/common/store/ipo.store';
import mixpanel from '../services/mixpanel';
import SessionContextProvider from '../contexts/sessionContextProvider';
import dynamic from 'next/dynamic';

const ThemeProvider = dynamic(
  () => import('@upstox/upstox-ui').then((mod) => mod.ThemeProvider),
  { ssr: false }
);

if (typeof window != 'undefined') {
  mixpanel.init();
}

type Props = AppProps & {
  renderer: IRenderer;
};

function MyApp({ Component, pageProps, renderer }: Props) {
  const store = useIPOStore(pageProps.ipos);
  const isIpoListed = pageProps?.ipo?.status === 'listed';
  const ipoId = pageProps?.ipo?.ipoId || '';
  return (
    <Provider ipoStore={store}>
      <FelaProvider renderer={renderer}>
        <HideCursorFocus />
        <SessionContextProvider isIpoListed={isIpoListed} ipoId={ipoId}>
          <ThemeProvider theme="light">
            <Component {...pageProps} />
          </ThemeProvider>
        </SessionContextProvider>
      </FelaProvider>
    </Provider>
  );
}
export default MyApp;
