import React from 'react';
import styles from './styles.module.scss';
import { ChevronDownIcon, ChevronUpIcon } from '@upstox/upstox-ui';
import DoubleColLinks from '../DoubleColLinks/DoubleColLinks';

export interface linkData {
  text: string;
  link: string;
}

interface InfoLinksProps {
  title: string;
  links: linkData[];
  handleToggleCollapse?: () => void;
  open?: boolean;
}

const InfoLinks: React.FC<InfoLinksProps> = ({
  title,
  links,
  open,
  handleToggleCollapse,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={handleToggleCollapse}>
        <h2 className={styles.title}>{title}</h2>
        {open ? (
          <ChevronUpIcon size="small" color="white" />
        ) : (
          <ChevronDownIcon size="small" color="white" />
        )}
      </div>
      <div className={styles.linksContainer}>
        {open && <DoubleColLinks links={links} title={title} />}
      </div>
    </div>
  );
};

export default InfoLinks;
