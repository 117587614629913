export type TrendingApiResponseKeys = 'ALL' | 'EQ' | 'FO' | 'INDEX' | 'MF';

export interface TrendingApiResponse {
  data: {
    [key in TrendingApiResponseKeys]: Array<ScripItem>;
  };
}

export interface MFScripItem {
  upstoxSchemeId: string;
  amcId: string;
  schemeClass: string;
  schemeLegalName: string;
  schemeName: string;
  assetClass: string;
  category: string;
  searchedField: string;
  distributionFrequency: string;
}

export interface ScripItem extends MFScripItem {
  instrumentKey: string;
  name: string;
  tradingSymbol: string;
  segment: string;
  instrumentType: string;
  isin: string;
  assetType: string;
  exchange: string;
  searchedField: string;
  exchangeToken: string;
  assetSymbol: string;
  expiry: string;
  strikePrice: number;
}

export const optionChainInstrumentType = 'OC';

export enum Segments {
  MF = 'MF',
  NSE_EQ = 'NSE_EQ',
  BSE_EQ = 'BSE_EQ',
  NSE_FO = 'NSE_FO',
  BSE_FO = 'BSE_FO',
  NSE_INDEX = 'NSE_INDEX',
  BSE_INDEX = 'BSE_INDEX',
}
export enum InstrumentType {
  FUT = 'FUT',
  CE = 'CE',
  PE = 'PE',
}

export enum SearchTabKeys {
  ALL = 'ALL',
  EQ = 'EQ',
  FO = 'FO',
  INDEX = 'INDEX',
  MF = 'MF',
}
export enum ScripItemType {
  SCRIP = 'SCRIP',
  OPTION_CHAIN = 'OPTION_CHAIN',
}

export interface SearchApiResponse {
  data: {
    searchList: Array<ScripItem>;
  };
}
