import React from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';
import { Text } from '@upstox/upstox-ui';
import {
  APPLE_APP_STORE_APP_DOWNLOAD_URL,
  GOOGLE_PLAY_STORE_APP_DOWNLOAD_URL,
} from 'src/components/Models/Actions/Footer/url-helper';
import { useWindowSize } from 'src/common/hooks/useWindowSize';
import { GTM_EVENTS } from 'src/constants';
import { registerGTMEvent } from 'utils/events';

const QRScanner: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 640;

  if (isMobile) {
    return (
      <div className={styles.mobileQRContainer}>
        <div className={styles.appInfo}>
          <Text color="text.white" variant="meta" style={{ maxWidth: '174px' }}>
            Download the mobile application today
          </Text>
          <div className={styles.storeLogos}>
            <div className={styles.playStoreLogo}>
              <a
                href={GOOGLE_PLAY_STORE_APP_DOWNLOAD_URL}
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={() => {
                  registerGTMEvent({
                    event: GTM_EVENTS.APP_DOWNLOAD__ICON_CLICK,
                    cta_text: 'Play store',
                    section_name: 'Footer',
                  });
                }}
              >
                <Image
                  src="/ipo-initial-public-offering/play-store.svg"
                  height={32}
                  width={32}
                  alt="play-store"
                  unoptimized={true}
                />
              </a>
            </div>
            <a
              href={APPLE_APP_STORE_APP_DOWNLOAD_URL}
              target="_blank"
              rel="nofollow noopener noreferrer"
              onClick={() => {
                registerGTMEvent({
                  event: GTM_EVENTS.APP_DOWNLOAD__ICON_CLICK,
                  cta_text: 'App store',
                  section_name: 'Footer',
                });
              }}
            >
              <Image
                src="/ipo-initial-public-offering/app-store.svg"
                height={32}
                width={32}
                alt="app-store"
                unoptimized={true}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.QRContainer}>
      <div className={styles.appInfo}>
        <Text color="text.white" variant="meta">
          Download the mobile application today
        </Text>
        <div className={styles.storeLogos}>
          <div className={styles.playStoreLogo}>
            <a
              href={GOOGLE_PLAY_STORE_APP_DOWNLOAD_URL}
              target="_blank"
              rel="nofollow noopener noreferrer"
              onClick={() => {
                registerGTMEvent({
                  event: GTM_EVENTS.APP_DOWNLOAD__ICON_CLICK,
                  cta_text: 'Play store',
                  section_name: 'Footer',
                });
              }}
            >
              <Image
                src="/ipo-initial-public-offering/play-store.svg"
                height={32}
                width={32}
                alt="play-store"
                unoptimized={true}
              />
            </a>
          </div>
          <a
            href={APPLE_APP_STORE_APP_DOWNLOAD_URL}
            target="_blank"
            rel="nofollow noopener noreferrer"
            onClick={() => {
              registerGTMEvent({
                event: GTM_EVENTS.APP_DOWNLOAD__ICON_CLICK,
                cta_text: 'App store',
                section_name: 'Footer',
              });
            }}
          >
            <Image
              src="/ipo-initial-public-offering/app-store.svg"
              height={32}
              width={32}
              alt="app-store"
              unoptimized={true}
            />
          </a>
        </div>
      </div>
      <div className={styles.QRLogoContainer}>
        <Image
          src="/ipo-initial-public-offering/qr-logo.svg"
          height={64}
          width={64}
          alt="qr-logo"
          unoptimized={true}
        />
      </div>
    </div>
  );
};

export default QRScanner;
