import React from 'react';
import cx from 'clsx';
import styles from './styles.module.scss';

export const SearchIcon = ({
  className,
  hover,
}: {
  className?: string;
  hover?: boolean;
}) => {
  if (hover)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        className={className}
      >
        <g opacity="0.1" clip-path="url(#clip0_4079_165339)">
          <path d="M14.6667 14.6667L10 10" stroke="white" stroke-width="2" />
          <path
            d="M11.3333 6.33594C11.3333 9.09736 9.09468 11.3359 6.33325 11.3359C3.57183 11.3359 1.33325 9.09736 1.33325 6.33594C1.33325 3.57451 3.57183 1.33594 6.33325 1.33594C9.09468 1.33594 11.3333 3.57451 11.3333 6.33594Z"
            stroke="white"
            stroke-width="2"
          />
        </g>
        <defs>
          <clipPath id="clip0_4079_165339">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  else
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        aria-hidden="true"
        viewBox="0 0 24 24"
        style={{ height: '16px', width: '16px' }}
        className={className}
      >
        <defs></defs>

        <path
          stroke="currentColor"
          strokeWidth="3"
          d="M22 22l-7-7m2-5.5a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
        ></path>
      </svg>
    );
};

// const inputVariants = cva(
//   'h-[34px] w-full rounded-lg border border-primary-dark bg-gray-base py-2 text-sm outline-none transition-colors placeholder:text-sm placeholder:text-gray-500 focus:border-2',
//   {
//     variants: {
//       iconPosition: {
//         start: 'pl-8 pr-2',
//         end: 'pl-2 pr-8',
//       },
//     },
//     defaultVariants: {
//       iconPosition: 'start',
//     },
//   },
// );

export interface SearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  iconPosition?: 'start' | 'end';
  className?: string;
  hover?: boolean; // For custom hover styles on input field (optional)
}

export const SearchInput = React.forwardRef<HTMLDivElement, SearchInputProps>(
  ({ iconPosition = 'start', className, ...props }, ref) => {
    const isIconAtStart = iconPosition === 'start';
    return (
      <div
        ref={ref}
        className={cx(styles['scrip-search-container'], className)}
      >
        <SearchIcon className={cx(styles['search-icon'])} hover={props.hover} />
        <input
          {...props}
          id="navbar-search-input"
          className={cx(
            styles['search-input'],
            props.hover ? styles['result-hover'] : '',
          )}
        />
      </div>
    );
  },
);

SearchInput.displayName = 'SearchInput';
