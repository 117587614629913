import React, { useState } from 'react';
import cx from 'clsx';
import { useDebounce } from './hooks';
import { useOnClickOutside } from './hooks';
import styles from './styles.module.scss';
import { SearchInput } from '../SearchInput';
import { GTM_EVENTS } from 'src/constants';
import { registerGTMEvent } from '../../../utils/events';

type IconPosition = 'start' | 'end';

interface Tab {
  displayName: string;
  key: string;
}

type RenderItem<T, V> = (item: T, activeTab: V) => React.ReactNode;

interface AutocompleteProps<T, V extends Tab> {
  /** Minimum number of chars required to trigger the dataSource. Default value = 3 */
  minCharRequired?: number;

  debounceTimeInMillisecs?: number;

  /** Default dropdown data to be shown when the input is focused and search input is empty */
  defaultDataSource: (
    setItems: React.Dispatch<React.SetStateAction<T[]>>,
    setLoader: React.Dispatch<React.SetStateAction<boolean>>,
    activeTab: V,
  ) => void;

  // onFocus;

  /** Dropdown data to be displayed in the dropdown list, called on every key press and tab change */
  dataSource: (
    searchTerm: string,
    setItems: React.Dispatch<React.SetStateAction<T[]>>,
    setLoader: React.Dispatch<React.SetStateAction<boolean>>,
    activeTab: V,
  ) => void;

  placeholder?: string;

  /** Array of objects to display tabs  */
  tabs: Array<V>;

  /** Search icon placement */
  iconPosition: IconPosition;

  /** React component used to display the items in the dropdown */
  renderItem: RenderItem<T, V>;

  renderLoader: () => React.ReactNode;

  onTabClicked: (
    searchTerm: string,
    setItems: React.Dispatch<React.SetStateAction<T[]>>,
    activeTab: V,
  ) => void;

  renderDropdown?: (
    searchTerm: string,
    items: T[],
    renderItem: RenderItem<T, V>,
    activeTab: V,
  ) => React.ReactNode;
  hover?: boolean;
}

export const Autocomplete = <T, V extends Tab>({
  minCharRequired = 3,
  defaultDataSource,
  dataSource,
  renderItem,
  iconPosition,
  tabs,
  renderDropdown,
  renderLoader,
  placeholder,
  debounceTimeInMillisecs = 300,
  hover,
}: AutocompleteProps<T, V>) => {
  const [value, setValue] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [items, setItems] = useState<T[]>([]);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSource = useDebounce(dataSource, debounceTimeInMillisecs);

  const ref = useOnClickOutside<HTMLDivElement>(() => {
    setIsDropdownOpen(false);
  });

  return (
    <div ref={ref} style={{ position: 'relative', width: '100%' }}>
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <SearchInput
          hover={hover}
          type="text"
          iconPosition={iconPosition}
          autoComplete="off"
          placeholder={placeholder || ''}
          onInput={(e) => {
            const value = (e.target as HTMLInputElement).value;
            setIsLoading(true);

            if (value.length >= minCharRequired) {
              debouncedSource(value, setItems, setIsLoading, activeTab);
            } else {
              defaultDataSource(setItems, setIsLoading, activeTab);
            }
            setValue(value);
          }}
          onFocus={() => {
            registerGTMEvent({
              event: GTM_EVENTS.SEARCH_CLICK,
              section_name: 'NA',
            });
            setIsDropdownOpen(true);
            if (value.length < minCharRequired) {
              setIsLoading(true);
              defaultDataSource(setItems, setIsLoading, activeTab);
            }
          }}
          value={value}
        />

        {isDropdownOpen && (
          <div className={styles.searchDropDownContainer}>
            <div className={styles.searchTabsContainer}>
              {tabs.map((tab, i) => (
                <button
                  className={cx(
                    styles.tabItem,
                    activeTab.key === tab.key && styles.activeTabItem,
                  )}
                  onClick={() => {
                    setActiveTab(tab);
                    setIsLoading(true);
                    if (value.length >= minCharRequired) {
                      debouncedSource(value, setItems, setIsLoading, tab);
                    } else {
                      defaultDataSource(setItems, setIsLoading, tab);
                    }
                  }}
                  key={i}
                >
                  {tab.displayName}
                </button>
              ))}
            </div>

            {isLoading && renderLoader()}

            {/* default dropdown style if custom dropdown is not provided */}
            {!renderDropdown && !isLoading && (
              <ul className="max-h-[300px] overflow-y-scroll">
                {items.map((item, i) => (
                  <li key={i}>{renderItem(item, activeTab)}</li>
                ))}
              </ul>
            )}

            {/* custom dropdown */}
            {renderDropdown &&
              !isLoading &&
              renderDropdown(value, items, renderItem, activeTab)}
          </div>
        )}
      </div>
    </div>
  );
};
