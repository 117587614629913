export const stockLinksSectionData = [
  {
    key: 'footer-third-popular_stocks',
    label: 'Popular Stocks',
    links: [
      {
        key: 'footer-third-popular_stocks-popular-stocks-irfc',
        label: 'IRFC Share Price',
        url: '/stocks/indian-railway-fin-corp-l-share-price/INE053F01010/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-suzlon',
        label: 'Suzlon Share Price',
        url: '/stocks/suzlon-energy-limited-share-price/INE040H01021/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-ireda',
        label: 'IREDA Share Price',
        url: '/stocks/indian-renewable-energy-share-price/INE202E01016/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-tata-motors',
        label: 'Tata Motors Share Price',
        url: '/stocks/tata-motors-limited-share-price/INE155A01022/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-yes-bank',
        label: 'Yes Bank Share Price',
        url: '/stocks/yes-bank-limited-share-price/INE528G01035/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-adani-enterprises',
        label: 'Adani Enterprises Share Price',
        url: '/stocks/adani-enterprises-limited-share-price/INE423A01024/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-hdfc-bank',
        label: 'HDFC Bank Share Price',
        url: '/stocks/hdfc-bank-ltd-share-price/INE040A01034/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-nhpc',
        label: 'NHPC Share Price',
        url: '/stocks/nhpc-ltd-share-price/INE848E01016/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-rvnl',
        label: 'RVNL Share Price',
        url: '/stocks/rail-vikas-nigam-limited-share-price/INE415G01027/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-sbi',
        label: 'SBI Share Price',
        url: '/stocks/state-bank-of-india-share-price/INE062A01020/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-tata-power',
        label: 'Tata Power Share Price',
        url: '/stocks/tata-power-co-ltd-share-price/INE245A01021/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-tata-steel',
        label: 'Tata Steel Share Price',
        url: '/stocks/tata-steel-limited-share-price/INE081A01020/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-adani-power',
        label: 'Adani Power Share Price',
        url: '/stocks/adani-power-ltd-share-price/INE814H01011/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-paytm',
        label: 'Paytm Share Price',
        url: '/stocks/one-97-communications-ltd-share-price/INE982J01020/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-pnb',
        label: 'PNB Share Price',
        url: '/stocks/punjab-national-bank-share-price/INE160A01022/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-zomato',
        label: 'Zomato Share Price',
        url: '/stocks/zomato-limited-share-price/INE758T01015/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-bel',
        label: 'BEL Share Price',
        url: '/stocks/bharat-electronics-ltd-share-price/INE263A01024/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-bhel',
        label: 'BHEL Share Price',
        url: '/stocks/bhel-share-price/INE257A01026/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-infosys',
        label: 'Infosys Share Price',
        url: '/stocks/infosys-limited-share-price/INE009A01021/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-irctc',
        label: 'IRCTC Share Price',
        url: '/stocks/indian-rail-tour-corp-ltd-share-price/INE335Y01020/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-lic',
        label: 'LIC Share Price',
        url: '/stocks/life-insura-corp-of-india-share-price/INE0J1Y01017/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-itc',
        label: 'ITC Share Price',
        url: '/stocks/itc-ltd-share-price/INE154A01025/',
      },
      {
        key: 'footer-third-popular_stocks-popular-stocks-reliance',
        label: 'Reliance Share Price',
        url: '/stocks/reliance-industries-ltd-share-price/INE002A01018/',
      },
    ],
  },
  {
    label: 'Indian Indices',
    key: 'footer-third-indian_indices',
    links: [
      {
        key: 'footer-third-indian_indices-indian-indices-nifty-50',
        label: 'Nifty 50',
        url: '/indices/nifty-50-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-nifty--next-50',
        label: 'Nifty Next 50',
        url: '/indices/nifty-next-50/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-nifty-bank',
        label: 'Nifty Bank',
        url: '/indices/nifty-bank-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-nifty-100',
        label: 'Nifty 100',
        url: '/indices/nifty-100-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-nifty-200',
        label: 'Nifty 200',
        url: '/indices/nifty-200-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-nifty-500',
        label: 'Nifty 500',
        url: '/indices/nifty-500-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-nifty-midcap-50',
        label: 'Nifty Midcap 50',
        url: '/indices/nifty-midcap-50-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-nifty-smlcap-50',
        label: 'NIFTY Smallcap 50',
        url: '/indices/nifty-smlcap-50-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-vix',
        label: 'India VIX',
        url: '/indices/india-vix-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-sensex',
        label: 'SENSEX',
        url: '/indices/sensex/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-bse-100',
        label: 'BSE100',
        url: '/indices/bse100-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-lrgcap',
        label: 'BSE LargeCap',
        url: '/indices/lrgcap-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-midcap',
        label: 'BSE MidCap',
        url: '/indices/midcap-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-smlcap',
        label: 'BSE SmallCap',
        url: '/indices/smlcap-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-bse-500',
        label: 'BSE500',
        url: '/indices/bse500-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-allcap',
        label: 'BSE AllCap',
        url: '/indices/allcap-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-bse200',
        label: 'BSE200',
        url: '/indices/bse200-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-bseipo',
        label: 'BSEIPO',
        url: '/indices/bseipo-share-price/',
      },
      {
        key: 'footer-third-indian_indices-indian-indices-india-smeipo',
        label: 'BSE SMEIPO',
        url: '/indices/smeipo-share-price/',
      },
    ],
  },
  {
    label: 'Mutual Funds',
    key: 'footer-third-mutual_funds',
    links: [
      {
        key: 'footer-third-mutual_funds-sbi-mutual-fund',
        label: 'SBI Mutual Fund',
        url: '/mutual-funds/explore/sbi-funds',
      },
      {
        key: 'footer-third-mutual_funds-hdfc-mutual-fund',
        label: 'HDFC Mutual Fund',
        url: '/mutual-funds/explore/hdfc-funds',
      },
      {
        key: 'footer-third-mutual_funds-nippon-india-mutual-fund',
        label: 'Nippon India Mutual Fund',
        url: '/mutual-funds/explore/nippon-funds',
      },
      {
        key: 'footer-third-mutual_funds-uti-mutual-fund',
        label: 'UTI Mutual Fund',
        url: '/mutual-funds/explore/uti-funds',
      },
      {
        key: 'footer-third-mutual_funds-dsp-mutual-fund',
        label: 'DSP Mutual Fund',
        url: '/mutual-funds/explore/dsp-funds',
      },
      {
        key: 'footer-third-mutual_funds-index-fund',
        label: 'Index Fund',
        url: '/mutual-funds/explore/index-funds',
      },
      {
        key: 'footer-third-mutual_funds-small-cap-mutual-fund',
        label: 'Small Cap Mutual Fund',
        url: '/mutual-funds/explore/small-cap-funds',
      },
      {
        key: 'footer-third-mutual_funds-liquid-funds',
        label: 'Liquid Funds',
        url: '/mutual-funds/explore/liquid-funds',
      },
    ],
  },
  {
    label: 'Tools',
    key: 'footer-third_tools',
    links: [
      {
        key: 'footer-third_tools-calculators-brokerage-calculator',
        label: 'Brokerage Calculator',
        url: '/calculator/brokerage-calculator/',
      },
      {
        key: 'footer-third_tools-calculators-margin-calculator',
        label: 'Margin Calculator',
        url: '/calculator/span-calculator/',
      },
      {
        key: 'footer-third_tools-calculators-sip-calculator',
        label: 'SIP Calculator',
        url: '/calculator/sip-calculator/',
      },
      {
        key: 'footer-third_tools-calculators-swp-calculator',
        label: 'SWP Calculator',
        url: '/calculator/swp/',
      },
      {
        key: 'footer-third_tools-calculators-nps-calculator',
        label: 'NPS Calculator',
        url: '/calculator/nps/',
      },
      {
        key: 'footer-third_tools-calculators-ppf-calculator',
        label: 'PPF Calculator',
        url: '/calculator/ppf-public-provident-fund/',
      },
      {
        key: 'footer-third_tools-calculators-compound-interest-calculator',
        label: 'Compound Interest calculator',
        url: '/calculator/compound-interest/',
      },
      {
        key: 'footer-third_tools-calculators-gratuity-calculator',
        label: 'Gratuity calculator',
        url: '/calculator/gratuity/',
      },
      {
        key: 'footer-third_tools-calculators-simple-interest-calculator',
        label: 'Simple Interest Calculator',
        url: '/calculator/simple-interest/',
      },
      {
        key: 'footer-third_tools-calculators-hra-calculator',
        label: 'HRA Calculator',
        url: '/calculator/hra-house-rent-allowance-calculator/',
      },
      {
        key: 'footer-third_tools-calculators-fd-calculator',
        label: 'FD Calculator',
        url: '/calculator/fd-fixed-deposit/',
      },
      {
        key: 'footer-third_tools-calculators-gst-calculator',
        label: 'GST Calculator',
        url: '/calculator/gst/',
      },
      {
        key: 'footer-third_calculators-calculators-mutual-fund-return-calculator',
        label: 'Mutual Fund Returns Calculator',
        url: '/calculator/mutual-fund-return/',
      },
      {
        key: 'footer-third_tools-calculators-emi-calculator',
        label: 'EMI Calculator',
        url: '/calculator/emi-calculator/',
      },
    ],
  },
  {
    label: 'IPO',
    key: 'footer-third-second-ipo',
    links: [
      {
        key: 'footer-third-second-ipo_ipo-ongoing-and-upcoming-ipos',
        label: 'Ongoing and Upcoming IPOs',
        url: '/ipo-initial-public-offering/',
      },
    ],
  },
  {
    label: 'Learning Center',
    key: 'footer-third-learning_center',
    links: [
      {
        key: 'footer-third-learning_center-uplearn-webinars',
        label: 'Webinars',
        url: '/uplearn/webinars/',
      },
      {
        key: 'footer-third-learning_center-uplearn-courses',
        label: 'Courses',
        url: '/uplearn/courses/',
      },
      {
        key: 'footer-third-learning_center-uplearn',
        label: 'Uplearn',
        url: '/uplearn/',
      },
      {
        key: 'footer-third-learning_center-demat-account-guide',
        label: 'Demat Account Guide',
        url: '/learning-center/demat-account/',
      },
      {
        key: 'footer-third-learning_center-trading-account-guide',
        label: 'Trading Account Guide',
        url: '/learning-center/trading-account/',
      },
      {
        key: 'footer-third-learning_center-online-trading-guide',
        label: 'Online Trading Guide',
        url: '/learning-center/online-trading/',
      },
      {
        key: 'footer-third-learning_center-intraday-trading-guide',
        label: 'Intraday Trading Guide',
        url: '/learning-center/intraday-trading/',
      },
      {
        key: 'footer-third-learning_center-futures-and-options-guide',
        label: 'Futures and Options Guide',
        url: '/learning-center/futures-and-options/',
      },
      {
        key: 'footer-third-learning_center-mutual-funds-guide',
        label: 'Mutual Funds Guide',
        url: '/learning-center/mutual-funds/',
      },
      {
        key: 'footer-third-learning_center-share-market-guide',
        label: 'Share Market Guide',
        url: '/learning-center/share-market/',
      },
      {
        key: 'footer-third-learning_center-ipo-guide',
        label: 'IPO Guide',
        url: '/learning-center/ipo/',
      },
      {
        key: 'footer-third-learning_center-stock-broker-guide',
        label: 'Stock Broker Guide',
        url: '/learning-center/stock-broker/',
      },
    ],
  },
  {
    label: 'Commodities',
    key: 'footer-third-commodities',
    links: [
      {
        key: 'footer-third-commodities-gold',
        label: 'Gold',
        url: '/gold-rates/',
      },
      {
        key: 'footer-third-commodities-silver',
        label: 'Silver',
        url: '/silver-rates/',
      },
      {
        key: 'footer-third-commodities-petrol',
        label: 'Petrol',
        url: '/petrol-price/',
      },
    ],
  },
];
