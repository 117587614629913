import React, { FunctionComponent } from 'react';
import { NavMenuProps } from './typings';
import { View, Text } from '@upstox/upstox-ui';
import { GTM_EVENTS } from 'src/constants';
import { registerGTMEvent } from 'utils/events';

const NavMenu: FunctionComponent<NavMenuProps> = ({
  section,
  menuTitle,
  navMenuItems,
  rel,
  parentStyle,
  childStyle,
}) => {
  return (
    <li className={'sub-menu-item'} style={parentStyle}>
      <Text as="h4">{menuTitle ? menuTitle : <>&nbsp;</>}</Text>
      <View>
        <ul>
          {navMenuItems.map((data, index) => (
            <li className={'menu-item'} key={index} style={childStyle} onClick={() => {
              registerGTMEvent({
                event: GTM_EVENTS.TOP_NAVIGATION_INTERACTION,
                cta_text: data.text,
                header_text: menuTitle,
                section_name: section,
              });
            }}>
              <a href={data.link} target={data.target} rel={rel}>
                {data.text}
              </a>
            </li>
          ))}
        </ul>
      </View>
    </li>
  );
};

export default NavMenu;
