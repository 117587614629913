import { color, spacing } from '@upstox/upstox-ui';
import { Background } from 'victory';

export const headerWrapper: CSSFunction = ({ theme }) => ({
  marginBottom: spacing(theme, [64]),
});
export const navMenu: CSSFunction = ({ theme }) => ({
  '> a': {
    textDecoration: 'none !important',
  },
});
export const signIn: CSSFunction = ({ theme }) => ({
  '& > a': {
    boxShadow: `0px 0px 0px 1px ${color(
      theme,
      'interactive.primary.default',
    )} inset`,
    color: color(theme, 'interactive.primary.default'),
    ':hover': {
      background: '#7132B0 !important',
      color: '#fff',
    },
  },
});

export const signInBtn = {
  fontSize: '16px',
  padding: '11px 24px !important',
  fontWeight: 700,
  lineHeight: '150% !important',
  height: '40px !important',
  borderRadius: '100px !important',
  backgroundColor: '#FFFFFF',
  color: '#111',
  border: '1px solid #43165C',
  letterSpacing: '-0.12px',
  '&:hover': {
    background: 'red !important',
    color: '#fff',
  },
};

export const signInBtnSmall = {
  ...signInBtn,
  fontSize: '10px',
  padding: '0 15px !important',
};

export const signInBtnMobile = {
  fontSize: '12px',
  padding: '6px 9px',
  fontWeight: 700,
  lineHeight: '16px',
  height: '34px !important',
  borderRadius: '25px !important',
  marginRight: '19px',
  color: '#111 !important',
};

export const openDematBtn = {
  fontSize: '12px',
  padding: '11px 24px !important',
  fontWeight: 700,
  lineHeight: '24px !important',
  height: '40px !important',
  borderRadius: '100px !important',
  color: '#fff !important',
  backgroundColor: '#542087 !important',
};

export const logoWhite: CSSFunction = ({ theme }) => ({
  '& svg': {
    '& path': {
      fill: color(theme, 'background.white'),
    },
  },
});
export const mobileSearch: CSSFunction = ({ theme }) => ({
  position: 'absolute',
  background: color(theme, 'background.default'),
  padding: spacing(theme, ['small', 'medium']),
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 3,
  '& > div': {
    width: '288px',
    marginRight: spacing(theme, ['medium']),
    margin: '0 auto',
  },
});
