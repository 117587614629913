export const MIN_CHAR_COUNT = 3;

export const SEARCH_TABS = [
  {
    displayName: 'All',
    key: 'ALL',
  },
  {
    displayName: 'Stocks',
    key: 'EQ',
  },
  {
    displayName: 'Indices',
    key: 'INDEX',
  },
  {
    displayName: 'F&O',
    key: 'FO',
  },
  {
    displayName: 'MF',
    key: 'MF',
  },
];

export const BLOCKED_SEGMENTS = ['NCD_FO', 'BCD_FO', 'MCX_FO', 'NSE_COM'];

export const NSE_ALLOWED_INSTRUMENT_TYPES = [
  'EQ',
  'SM',
  'BE',
  'FUT',
  'PE',
  'CE',
  'INDEX',
  'OC',
  'ST',
];
export const BSE_BLOCKED_INSTRUMENT_TYPES = ['F', 'G', 'GC'];

export const SEARCH_URLS = {
  TRENDING_API_URL: '/social-reaction/open/v2/trending/',
  SEARCH_API_URL: '/search/open/v1?',
  SOCIAL_REACTION_COUNTER: '/social-reaction/open/v2/trending/',
};
