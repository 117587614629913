import React from 'react';
import styles from './styles.module.scss';
import { socialHandles } from './constants';
import { registerGTMEvent } from 'utils/events';
import { GTM_EVENTS } from 'src/constants';

const SocialMedia: React.FC = () => {
  return (
    <div className={styles.socialMediaRow}>
      {socialHandles.map((step, i) => {
        return (
          <a
            key={i}
            href={step.link}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              registerGTMEvent({
                event: GTM_EVENTS.SOCIAL_MEDIA_INTERACTION,
                cta_text: step.name,
                section_name: 'NA'
              });
            }}
          >
            {step.icon}
          </a>
        );
      })}
    </div>
  );
};

export default SocialMedia;
