import React from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';
import { Text } from '@upstox/upstox-ui';

const Address: React.FC = () => {
  return (
    <div>
      <Image
        src="/ipo-initial-public-offering/upstox-footer-logo.png"
        height={51}
        width={110}
        alt="upstox logo"
        unoptimized={true}
      />
      <div className="footer-upstox-info-address">
        <Text color="text.white">
          30th Floor, Sunshine Tower, Senapati Bapat Marg, Dadar (W), Mumbai,
          Maharashtra 400013
        </Text>
      </div>
      <div className={styles.contactUsContainer}>
        <div className={styles.callUsEmailContainer}>
          <span>Call:</span>
          <span className={styles.contact}>
            <a href="tel:022-41792999">022-41792999</a>
          </span>
        </div>
        <div className={styles.callUsEmailContainer}>
          <span>E-mail:</span>
          <span className={styles.contact}>
            <a href="mailto:support@upstox.com">support@upstox.com</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Address;
