import React, { useState } from 'react';
import { Text } from '@upstox/upstox-ui';

import { stockLinksSectionData } from '../constants';
import { GTM_EVENTS } from 'src/constants';
import { registerGTMEvent } from 'utils/events';

const StockLinksSectionDesktop: React.FC = () => {
  const [activeKey, setActiveKey] = useState<string | null>(
    stockLinksSectionData.length > 0 ? stockLinksSectionData[0].key : null,
  );

  const handleLinkClick = (key: string) => {
    if (activeKey !== key) {
      setActiveKey(key);
    }
  };

  return (
    <div>
      <div className="footer-stock-links">
        {stockLinksSectionData.map((link) => (
          <Text
            key={link.key}
            className={`footer-stock-link ${
              activeKey === link.key ? 'active' : ''
            }`}
            onClick={() => handleLinkClick(link.key)}
          >
            {link.label}
          </Text>
        ))}
      </div>
      <div className="footer-stock-sub-links">
        {stockLinksSectionData
          .find((link) => link.key === activeKey)
          ?.links.map((subLink, index) => (
            <Text
              key={index}
              className="footer-stock-sub-link"
              color="text.white"
            >
              <a
                href={subLink.url}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-stock-sub-link"
                color="text.white"
                onClick={() => {
                  registerGTMEvent({
                    event: GTM_EVENTS.FOOTER_INTERACTION,
                    cta_text: subLink.label,
                    header_text: stockLinksSectionData.find(
                      (link) => link.key === activeKey,
                    )?.label,
                    section_name: 'NA',
                  });
                }}
              >
                {subLink.label}
              </a>
            </Text>
          )) || (
          <Text className="footer-stock-sub-link" color="text.white">
            No sublinks available.
          </Text>
        )}
      </div>
    </div>
  );
};

export default StockLinksSectionDesktop;
