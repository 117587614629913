export namespace CONSTANTS {
  export const LANDING_PAGE_TITLE =
    'IPO - Upcoming IPO in India - IPO Watch - Allotment Status';

  export const LANDING_META_DESCRIPTION =
    'IPO Watch - Find Latest & Upcoming IPOs List 2022. Know about IPOs Timeline, allotment status, Reviews, listing details and more at Upstox.com. Apply Online Today.';
}

export namespace ROUTES {
  export const ONBOARDING_ROUTE = 'https://upstox.com/open-demat-account/';
}

export const GTM_EVENTS = {
  TOP_NAVIGATION_INTERACTION: 'top_navigation_interaction',
  SEARCH_CLICK: 'search_click',
  SEARCH_INTERACTION: 'search_interaction',
  START_INVESTING_CLICK: 'start_investing_click',
  SOCIAL_MEDIA_INTERACTION: `social_media_interaction`,
  APP_DOWNLOAD__ICON_CLICK: 'app_download_icon_click',
  FOOTER_INTERACTION: 'footer_interaction',
};
