import React, { FunctionComponent, useState, useEffect } from 'react';
import { UpstoxLogo, View, Text, CloseIcon } from '@upstox/upstox-ui';
import Drawer from '../../../common/components/Drawer/drawer';
import Tab from '../../Tabs/components/Tab';
import MenuTitle from '../MenuTitle';
import {
  investData,
  investTools,
  tools,
  learningCenter,
  helpCenter,
  resourcesData,
  tools2,
  learningCenter2,
  aboutUs,
  tools3,
  investHolidays,
  investExtra,
  tradeData,
  UP_LEARN_COMMUNITY_URL,
  UP_LEARN_URL,
} from '../constants';
import { getOnboardingUrl, getSignInUrl } from '../../../helpers';
import { GTM_EVENTS, ROUTES } from 'src/constants';
import {
  ABOUT_US_URL,
  ALL_CALCULATORS,
  HELP_CENTER,
  INVEST_IN_SHARE_URL,
  LEARNING_CENTER_URL,
  TICK_BY_TICK_TRADING_URL,
  TRADING_HOLIDAYS_URL,
  UPSTOX_FUTURES_AND_OPTIONS_URL,
} from 'src/components/Models/Actions/Footer/url-helper';
import { registerGTMEvent } from 'utils/events';

interface Props {
  showDrawer?: boolean;
  toggleDrawer?: () => void;
  CloseButton?: () => void;
  children?: React.ReactNode;
}

const ResponsiveMenu: FunctionComponent<Props> = ({
  showDrawer,
  toggleDrawer,
}) => {
  const [tabId, setTabId] = useState(-1);
  const [onboardingUrl, setOnboardingUrl] = useState<string>(
    ROUTES.ONBOARDING_ROUTE,
  );
  const [signInUrl, setSignUrl] = useState(process.env.LOGIN_URL);

  useEffect(() => {
    setOnboardingUrl(getOnboardingUrl());
    setSignUrl(getSignInUrl());
  }, []);

  const tabData = [
    {
      title: 'Invest',
    },
    {
      title: 'Trade',
    },
    {
      title: 'News',
      link: 'https://upstox.com/news/', // Add the appropriate URL here
    },
    {
      title: 'UpLearn',
    },
    {
      title: 'About us',
    },
  ];

  const handleTabChange = (id: number) => {
    if (tabId !== id) {
      registerGTMEvent({
        event: GTM_EVENTS.TOP_NAVIGATION_INTERACTION,
        cta_text: tabData[id].title,
        section_name: 'NA',
      });
    }
    setTabId((prevTabId) => (prevTabId === id ? -1 : id));
  };

  return (
    <>
      {showDrawer && (
        <Drawer
          withCloseButton={false}
          style={{ height: '100%', borderRadius: 0 }}
          offset={0}
          zIndex={5}
        >
          <View className={`mobile-menu`}>
            <View flexDirection="column" className="MobileMenuItems full-width">
              <View as="ul" className={'responsive-header'}>
                <View as="li">
                  <a
                    href={`${process.env.BASE_URL}/ipo-initial-public-offering`}
                  >
                    <img
                      src="/ipo-initial-public-offering/mobile-logo-white.webp"
                      alt="upstox-logo"
                      width={158}
                      height={26}
                      className="logo"
                      style={{ width: '158px', height: '26px' }}
                    />
                  </a>{' '}
                </View>
                <View as="li">
                  <CloseIcon
                    size="large"
                    color="text.3"
                    onClick={toggleDrawer}
                  />
                </View>
              </View>

              {/* <View flexDirection="column" className={'r-topmenu'}>
                <View as="ul" flexDirection="column" className={'menu-items'}>
                  <View as="li">
                    <a href={'onboardingUrl'}>
                      <Text color="text.white" variant="bodyBold" size="large">
                        Create Account
                      </Text>
                    </a>
                  </View>
                  <View as="li">
                    <a href={signInUrl}>
                      <Text color="text.white" variant="bodyBold" size="large">
                        Sign in
                      </Text>
                    </a>
                  </View>
                </View>
              </View> */}

              <div className={'r-topmenu'}>
                <ul className={'menu-items'}>
                  <li>
                    <a href={onboardingUrl}>Open Demat Account</a>
                  </li>
                </ul>
                <ul className={'menu-items'}>
                  <li>
                    <a href={signInUrl}>Sign in</a>
                  </li>
                </ul>
              </div>

              <View flexDirection="column" className="r-tabs">
                {tabData.map((tab, index) => {
                  function cx(
                    tab: any,
                    arg1: { [x: number]: any },
                    arg2: { [x: number]: any },
                    arg3: { [x: number]: any },
                    arg4: { [x: number]: any },
                    arg5: any,
                    className: any,
                  ): string {
                    throw new Error('Function not implemented.');
                  }

                  return (
                    <View key={index} flexDirection="column">
                      {tab.link ? (
                        <a href={tab.link} style={{ textDecoration: 'none' }}>
                          <Tab
                            id={index}
                            title={
                              <Text
                                color="text.white"
                                variant="bodyBold"
                                size="large"
                                lineHeight="12px" // Add this line
                              >
                                {tab.title}
                              </Text>
                            }
                            key={index}
                            className={`r-header full-width ${
                              tab.title === 'News' ? 'noAfter' : ''
                            }`}
                          />
                        </a>
                      ) : (
                        <Tab
                          id={index}
                          title={
                            <Text
                              color="text.white"
                              variant="bodyBold"
                              size="large"
                              lineHeight="12px" // Add this line
                            >
                              {tab.title}
                            </Text>
                          }
                          key={index}
                          onTabSelect={handleTabChange}
                          isActive={index === tabId}
                          activeTabClassName={'r-header-active'}
                          className="r-header full-width"
                        />
                      )}
                      {tabId == 0 && index === 0 && (
                        <View flexDirection="column" className="r-collapse">
                          <MenuTitle
                            subMenuTitle="Invest"
                            subMenuItems={investData}
                            mainUrl={INVEST_IN_SHARE_URL}
                            section="Invest"
                          />
                          <MenuTitle
                            subMenuTitle="Tools"
                            subMenuItems={investTools}
                            mainUrl={ALL_CALCULATORS}
                            section="Invest"
                          />
                          <MenuTitle
                            subMenuTitle="Holidays"
                            subMenuItems={investHolidays}
                            mainUrl={TRADING_HOLIDAYS_URL}
                            section="Invest"
                          />
                        </View>
                      )}
                      {tabId == 1 && index === 1 && (
                        <View flexDirection="column" className="r-collapse">
                          <MenuTitle
                            subMenuTitle="Tick-By-Tick"
                            subMenuItems={[]}
                            mainUrl={TICK_BY_TICK_TRADING_URL}
                            section="Trade"
                            allowGtmEvents={false}
                          />
                          <MenuTitle
                            subMenuTitle="Trade"
                            subMenuItems={[...tradeData]}
                            mainUrl={UPSTOX_FUTURES_AND_OPTIONS_URL}
                            section="Trade"
                          />
                          <MenuTitle
                            subMenuTitle="Tools"
                            subMenuItems={[...tools, ...tools2, ...tools3]}
                            mainUrl={ALL_CALCULATORS}
                            section="Trade"
                          />
                        </View>
                      )}
                      {tabId == 3 && index === 3 && (
                        <View flexDirection="column" className="r-collapse">
                          <MenuTitle
                            allowGtmEvents={false}
                            subMenuTitle="Upstox community"
                            subMenuItems={[]}
                            mainUrl={UP_LEARN_COMMUNITY_URL}
                            section="UpLearn"
                          />
                          <MenuTitle
                            allowGtmEvents={false}
                            subMenuTitle="UpLearn"
                            subMenuItems={[]}
                            mainUrl={UP_LEARN_URL}
                            section="UpLearn"
                          />
                          <MenuTitle
                            subMenuTitle="Learning Center"
                            subMenuItems={[
                              ...learningCenter,
                              ...learningCenter2,
                            ]}
                            mainUrl={LEARNING_CENTER_URL}
                            section="UpLearn"
                          />
                          <MenuTitle
                            subMenuTitle="Help Center"
                            subMenuItems={helpCenter}
                            mainUrl={HELP_CENTER}
                            section="UpLearn"
                          />
                          <MenuTitle
                            subMenuTitle="Resources"
                            subMenuItems={resourcesData}
                            mainUrl={process.env.BASE_URL}
                            section="UpLearn"
                          />
                        </View>
                      )}
                      {tabId == 4 && index === 4 && (
                        <View flexDirection="column" className="r-collapse">
                          <MenuTitle
                            subMenuTitle="About us"
                            subMenuItems={aboutUs}
                            mainUrl={ABOUT_US_URL}
                            section="About us"
                          />
                        </View>
                      )}
                    </View>
                  );
                })}
                <ul className={'menu-items'}>
                  <li>
                    <a href="https://upstox.com/become-sub-broker/">
                      Partner with us
                    </a>
                  </li>
                </ul>

                <ul className={'menu-items'}>
                  <li>
                    <a href="https://upstox.com/brokerage-charges/">Pricing</a>
                  </li>
                </ul>
                {/* <View as="ul" flexDirection="column" className={'menu-items'}>
                  <View as="li" className="mb0">
                    <Text variant="bodyBold" color="text.white">
                      <a href="https://upstox.com/partner/">
                        <Text
                          color="text.white"
                          variant="bodyBold"
                          size="large"
                        >
                          Partner with us
                        </Text>
                      </a>
                    </Text>
                  </View>
                  <View as="li">
                    <a href="https://upstox.com/brokerage-charges/">
                      <Text color="text.white" variant="bodyBold" size="large">
                        Pricing
                      </Text>
                    </a>
                  </View>
                </View> */}
              </View>
            </View>
          </View>
        </Drawer>
      )}
    </>
  );
};

export default ResponsiveMenu;
