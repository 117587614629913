import {
  INVEST_URL,
  MUTUAL_FUNDS_URL,
  IPOS_URL,
  NEW_SOVEREIGN_GOLD_BONDS,
  MF_RETURNS_CALCULATOR_URL,
  SIP_CALCULATOR_URL,
  ELSS_CALCULATOR,
  BROKERAGE_CALCULATOR_URL,
  GOLD_RATES_TRACKER_URL,
  TRADING_HOLIDAYS_URL,
  NSE_HOLIDAYS_URL,
  BSE_HOLIDAYS_URL,
  MCX_HOLIDAYS_URL,
  MUHURAT_TRADING_SESSION_URL,
  SHARE_MARKET_TIMING_URL,
  UPSTOX_FUTURES_AND_OPTIONS_URL,
  UPSTOX_TRADING_VIEW_URL,
  CHART_360,
  MARGIN_CALCULATOR_URL,
  NPV_CALCULATOR,
  LUMPSUM_CALCULATOR_URL,
  NPS_CALCULATOR,
  OPTION_VALUE_CALCULATOR,
  SWP_CALCULATOR,
  FUTURE_VALUE_CALCULATOR,
  TRADE_PRICE_CHECKER_URL,
  ALL_CALCULATORS,
  ABOUT_US_URL,
  LIFE_AT_UPSTOX,
  PRO_WEB_URL,
  PRO_MOBILE_URL,
  LEARNING_CENTER_DEMAT_URL,
  LEARNING_CENTER_TRADING_URL,
  LEARNING_CENTER_ONLINE_URL,
  LEARNING_CENTER_INTRADAY_URL,
  LEARNING_CENTER_OPTIONS_URL,
  LEARNING_CENTER_MF_URL,
  LEARNING_CENTER_SHAREMARKET_URL,
  LEARNING_CENTER_IPO_URL,
  HELP_CENTER_AO,
  HELP_CENTER_POSTTRADE,
  HELP_CENTER_HELPDESK,
  HELP_CENTER_ANNOUNCEMENTS,
  TRADING_GLOSSARY_URL,
  MARKET_TALK_URL,
  STOCK_MARKET_HOLIDAYS_URL,
  SHARE_MARKET_TIMINGS_URL,
} from '../Models/Actions/Footer/url-helper';

export const investData = [
  {
    text: 'Stocks',
    link: INVEST_URL,
  },
  {
    text: 'Mutual Funds',
    link: MUTUAL_FUNDS_URL,
  },
  {
    text: 'IPOs',
    link: IPOS_URL,
  },
  {
    text: 'Sovereign Gold Bonds',
    link: NEW_SOVEREIGN_GOLD_BONDS,
  },
];
export const investTools = [
  {
    text: 'MF Returns Calculator',
    link: MF_RETURNS_CALCULATOR_URL,
  },
  {
    text: 'SIP Calculator',
    link: SIP_CALCULATOR_URL,
  },
  {
    text: 'ELSS Calculator',
    link: ELSS_CALCULATOR,
  },

  {
    text: 'Brokerage Calculator',
    link: BROKERAGE_CALCULATOR_URL,
  },
  {
    text: 'Gold Rate Tracker',
    link: GOLD_RATES_TRACKER_URL,
  },
];

export const investHolidays = [
  {
    text: 'Trading Holidays 2025',
    link: TRADING_HOLIDAYS_URL,
  },
  {
    text: 'NSE Holidays 2025',
    link: NSE_HOLIDAYS_URL,
  },
  {
    text: 'BSE Holidays 2025',
    link: BSE_HOLIDAYS_URL,
  },
  {
    text: 'MCX Holidays 2025',
    link: MCX_HOLIDAYS_URL,
  },
  {
    text: 'Share Market Timing',
    link: SHARE_MARKET_TIMINGS_URL,
  },
];

export const investExtra = [
  {
    text: 'Muhurat Trading Session',
    link: MUHURAT_TRADING_SESSION_URL,
  },
  {
    text: 'Share Market Timing',
    link: SHARE_MARKET_TIMING_URL,
  },
];

export const tradeData = [
  {
    text: 'Futures & Options',
    link: UPSTOX_FUTURES_AND_OPTIONS_URL,
  },
  {
    text: 'Commodities',
    link: UPSTOX_FUTURES_AND_OPTIONS_URL,
  },
  {
    text: 'Currency',
    link: UPSTOX_FUTURES_AND_OPTIONS_URL,
  },
  {
    text: 'Upstox x TradingView',
    link: UPSTOX_TRADING_VIEW_URL,
  },
  {
    text: 'Chart 360',
    link: CHART_360,
  },
];
export const tools = [
  {
    text: 'Brokerage Calculator',
    link: BROKERAGE_CALCULATOR_URL,
  },
  {
    text: 'Span Calculator',
    link: MARGIN_CALCULATOR_URL,
  },
  {
    text: 'NPV Calculator',
    link: NPV_CALCULATOR,
  },
  {
    text: 'Lumpsum Calculator',
    link: LUMPSUM_CALCULATOR_URL,
  },
];
export const tools2 = [
  {
    text: 'NPS Calculator',
    link: NPS_CALCULATOR,
  },
  {
    text: 'Option Value Calculator',
    link: OPTION_VALUE_CALCULATOR,
  },
  {
    text: 'SWP Calculator',
    link: SWP_CALCULATOR,
  },
  {
    text: 'Future Value Calculator',
    link: FUTURE_VALUE_CALCULATOR,
  },
];
export const tools3 = [
  {
    text: 'Trade Price Checker',
    link: TRADE_PRICE_CHECKER_URL,
  },
  {
    text: 'View all Calculators',
    link: ALL_CALCULATORS,
  },
];

export const aboutUs = [
  {
    text: 'Our Story',
    link: ABOUT_US_URL,
  },
  {
    text: 'Life at upstox',
    link: LIFE_AT_UPSTOX,
  },
];
//
export const productData = [
  {
    text: 'Web/Desktop',
    link: PRO_WEB_URL,
  },
  {
    text: 'Mobile Apps',
    link: PRO_MOBILE_URL,
  },
];

export const learningCenter = [
  {
    text: 'Demat Account',
    link: LEARNING_CENTER_DEMAT_URL,
  },
  {
    text: 'Trading Account',
    link: LEARNING_CENTER_TRADING_URL,
  },
  {
    text: 'Online Trading',
    link: LEARNING_CENTER_ONLINE_URL,
  },
  {
    text: 'Intraday Trading',
    link: LEARNING_CENTER_INTRADAY_URL,
  },
];

export const learningCenter2 = [
  {
    text: 'Futures and Options',
    link: LEARNING_CENTER_OPTIONS_URL,
  },
  {
    text: 'Mutual Funds',
    link: LEARNING_CENTER_MF_URL,
  },
  {
    text: 'Share Market',
    link: LEARNING_CENTER_SHAREMARKET_URL,
  },
  {
    text: 'IPO',
    link: LEARNING_CENTER_IPO_URL,
  },
];
export const helpCenter = [
  {
    text: 'Account Opening',
    link: HELP_CENTER_AO,
  },
  {
    text: 'Post Trade Actions',
    link: HELP_CENTER_POSTTRADE,
  },
  {
    text: 'Submit Helpdesk Ticket',
    link: HELP_CENTER_HELPDESK,
  },
  {
    text: 'Announcements',
    link: HELP_CENTER_ANNOUNCEMENTS,
  },
];
export const resourcesData = [
  {
    text: 'Trading Glossary',
    link: TRADING_GLOSSARY_URL,
  },
  {
    text: 'Market Talk',
    link: MARKET_TALK_URL,
  },
  {
    text: 'Stock Market Holidays Calendar 2025',
    link: STOCK_MARKET_HOLIDAYS_URL,
  },
];

export const tbtNavBanner = '/ipo-initial-public-offering/tbt-nav-banner.png';
export const upstoxLogo =
  '/ipo-initial-public-offering/upstox-logo-primary-invest.webp';
export const upstoxlogoWhite =
  '/ipo-initial-public-offering/white-desktop-logo.webp';
export const upLearnCommunityImage =
  '/ipo-initial-public-offering/community.webp';
export const upstoxMobileLogo = '/ipo-initial-public-offering/mobile-logo.webp';
export const upstoxMobileLogoWhite =
  '/ipo-initial-public-offering/mobile-logo-white.webp';
export const upLearnImage = '/ipo-initial-public-offering/uplearn.webp';
export const UP_LEARN_COMMUNITY_URL = 'https://community.upstox.com/';
export const UP_LEARN_URL = 'https://upstox.com/uplearn/';
const UPSTOX_TBT = 'https://upstox.com/tick-by-tick-trading/';
export const imageMenuList = [
  {
    imageSrc: upLearnCommunityImage,
    alt: 'Upstox Community',
    link: UP_LEARN_COMMUNITY_URL,
    target: '_blank',
  },
  {
    imageSrc: upLearnImage,
    alt: 'Uplearn',
    link: UP_LEARN_URL,
    target: '_self',
  },
];

export const tradeImageMenu = [
  {
    imageSrc: tbtNavBanner,
    alt: 'trade',
    link: UPSTOX_TBT,
    target: '_self',
  },
];

export const upLearnLinks = [
  {
    text: 'UpLearn',
    link: UP_LEARN_URL,
  },
  {
    text: 'Community',
    link: UP_LEARN_COMMUNITY_URL,
  },
];
