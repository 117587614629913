import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import {
  View,
  Text,
  Link,
  Button,
  CloseIcon,
  SearchSimpleIcon,
} from '@upstox/upstox-ui';
import * as css from './Header.style';
import ResponsiveMenu from './ResponsiveMenu/Responsive';
import NavMenu from './NavMenu';
import {
  tools,
  helpCenter,
  resourcesData,
  tools2,
  learningCenter,
  learningCenter2,
  investData,
  investTools,
  tradeData,
  tools3,
  aboutUs,
  investHolidays,
  investExtra,
} from './constants';
import { getOnboardingUrl, getSignInUrl } from '../../helpers';
import { NavScripSearchInput } from '../NavScripSearchInput';
import { isStorageAvailable } from '../../common/utils/utils';
import UpstoxInvestLogoBlue from '../../../public/upstox-logo-primary-invest.svg';
import { ROUTES } from 'src/constants';
import MenuTitle from './MenuTitle';
import { TICK_BY_TICK_TRADING_URL } from '../Models/Actions/Footer/url-helper';

const Header: FunctionComponent = () => {
  const [onboardingUrl, setOnboardingUrl] = useState<string>(
    ROUTES.ONBOARDING_ROUTE,
  );
  const [showSearchBox, setShowSearchBox] = useState(true);
  useEffect(() => {
    setOnboardingUrl(getOnboardingUrl());
  }, []);

  const [clas, setClass] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    setShowSearchBox(isStorageAvailable());
  }, []);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const addClass = () => {
    setClass('result_hover');
  };

  const removeClass = () => {
    setClass('');
  };

  const onSignClick = (target) => {
    window.open(getSignInUrl(), target);
  };

  return (
    <Fragment>
      <View css={css.headerWrapper}>
        <View className="mobileHeader">
          <View alignItems="center">
            <Link href={`${process.env.BASE_URL}/`} className="d-flex">
              <img src={UpstoxInvestLogoBlue} alt="Upstox Logo" />
            </Link>
          </View>
          <View alignItems="center">
            {showSearchBox && (
              <View margin={[0, 16, 0, 0]}>
                <View>
                  <SearchSimpleIcon
                    width="20"
                    height="20"
                    onClick={() => setShowSearch(!showSearch)}
                  />
                </View>
                {showSearch && (
                  <View css={css.mobileSearch} alignItems="center">
                    <NavScripSearchInput hover={clas === 'result_hover'} />
                    <CloseIcon
                      className="closeIcon"
                      size="large"
                      onClick={() => setShowSearch(!showSearch)}
                    />
                  </View>
                )}
              </View>
            )}
            <View css={css.signIn}>
              <Button
                as="a"
                variant="secondary"
                className="nav-btn-link-default"
                onClick={() => onSignClick('_self')}
                style={{ ...css.signInBtnMobile, ...{ height: '34px' } }}
              >
                Sign in
              </Button>
            </View>
            <Text as="span" className={'hamburger-bars'} onClick={toggleDrawer}>
              {' '}
            </Text>
            <ResponsiveMenu
              showDrawer={showDrawer}
              toggleDrawer={toggleDrawer}
            />
          </View>
        </View>

        <View
          className={`${'navigation-container'} ${clas}`}
          onMouseLeave={removeClass}
        >
          <View className={'main-navigation full-width'}>
            <View alignItems="center" style={{ display: 'flex', gap: '24px' }}>
              <View className={'logo'}>
                <Link href={`${process.env.BASE_URL}/`}>
                  {clas === 'result_hover' ? (
                    <View css={css.logoWhite}>
                      <img
                        src={
                          '/ipo-initial-public-offering/white-desktop-logo.webp'
                        }
                        alt="upstox-logo"
                        width={78}
                        height={48}
                      />
                    </View>
                  ) : (
                    <View>
                      <img
                        src={
                          '/ipo-initial-public-offering/upstox-logo-primary-invest.webp'
                        }
                        alt="upstox-logo"
                      />
                    </View>
                  )}
                </Link>
              </View>

              <nav className={'links'}>
                <ul>
                  <li
                    onMouseOver={addClass}
                    onMouseLeave={removeClass}
                    className="product-tools"
                  >
                    <a
                      href="https://upstox.com/invest-in-share-market/"
                      className="nav-link"
                    >
                      <Text color="text.1">Invest</Text>
                    </a>
                    <ul className={'sub-menu'} style={{ display: 'flex' }}>
                      <NavMenu
                        menuTitle="Invest"
                        navMenuItems={investData}
                        parentStyle={{ marginRight: '80px' }}
                      />
                      <NavMenu
                        menuTitle="Tools"
                        navMenuItems={investTools}
                        parentStyle={{ marginRight: '80px' }}
                      />
                      <NavMenu
                        menuTitle="Holidays"
                        navMenuItems={investHolidays}
                        childStyle={{ marginRight: '64px' }}
                      />
                    </ul>
                  </li>
                  <li
                    onMouseOver={addClass}
                    onMouseLeave={removeClass}
                    className="product-tools"
                  >
                    <a
                      href="https://upstox.com/trade-in-futures-and-options/"
                      className="nav-link"
                    >
                      <Text color="text.1">Trade</Text>
                    </a>
                    <ul className={'sub-menu'} style={{ display: 'flex' }}>
                      <div className="image-wrapper">
                        <a
                          target="_blank"
                          href="https://upstox.com/tick-by-tick-trading/"
                        >
                          <img
                            src="/ipo-initial-public-offering/tbt-nav-banner.svg"
                            width="300"
                            height="269"
                            loading="lazy"
                            alt="Go to Upstox uplearn"
                            style={{
                              borderRadius: '10px',
                              overflow: 'hidden',
                            }}
                          />
                        </a>
                      </div>
                      <NavMenu
                        menuTitle="Trade"
                        navMenuItems={tradeData}
                        parentStyle={{ marginRight: '80px' }}
                      />
                      <NavMenu
                        menuTitle="Tools"
                        navMenuItems={tools}
                        childStyle={{ marginRight: '64px' }}
                      />
                      <NavMenu
                        menuTitle=""
                        navMenuItems={tools2}
                        childStyle={{ marginRight: '64px' }}
                      />
                      <NavMenu menuTitle="" navMenuItems={tools3} />
                    </ul>
                  </li>
                  <li className="product-tools no-hover-border">
                    <a href="https://upstox.com/news/" className="nav-link">
                      <Text size="large" color="text.1">
                        News
                      </Text>
                    </a>
                  </li>
                  <li
                    onMouseOver={addClass}
                    onMouseLeave={removeClass}
                    className="product-tools"
                  >
                    <a href="https://upstox.com/uplearn/" className="nav-link">
                      <Text size="large" color="text.1">
                        UpLearn
                      </Text>
                    </a>
                    <ul className={'sub-menu uplearn-wrapper'}>
                      <div className="image-wrapper">
                        <a target="_blank" href="https://community.upstox.com/">
                          <img
                            src="/ipo-initial-public-offering/nav_uplearn_1.svg"
                            width="299"
                            height="116"
                            loading="lazy"
                            alt="Go to Upstox uplearn"
                            style={{
                              borderRadius: '10px',
                              overflow: 'hidden',
                            }}
                          />
                        </a>
                        <a target="_blank" href="https://upstox.com/uplearn/">
                          <img
                            src="/ipo-initial-public-offering/nav_uplearn_2.svg"
                            width="299"
                            height="116"
                            loading="lazy"
                            alt="Go to Upstox uplearn"
                            style={{
                              borderRadius: '10px',
                              overflow: 'hidden',
                            }}
                          />
                        </a>
                      </div>
                      <NavMenu
                        menuTitle="Learning Center"
                        navMenuItems={learningCenter}
                        childStyle={{ marginRight: '40px' }}
                      />
                      <NavMenu
                        menuTitle={''}
                        navMenuItems={learningCenter2}
                        parentStyle={{ marginRight: '56px' }}
                      />
                      <NavMenu
                        menuTitle="Help Center"
                        navMenuItems={helpCenter}
                        parentStyle={{ marginRight: '56px' }}
                      />
                      <NavMenu
                        menuTitle="Resources"
                        navMenuItems={resourcesData}
                      />
                    </ul>
                  </li>
                  <li
                    onMouseOver={addClass}
                    onMouseLeave={removeClass}
                    className="product-tools"
                  >
                    <a href="https://upstox.com/about/" className="nav-link">
                      <Text color="text.1">About us</Text>
                    </a>
                    <ul className={'sub-menu'}>
                      <NavMenu menuTitle="About Us" navMenuItems={aboutUs} />
                    </ul>
                  </li>
                  <li className="product-tools no-hover-border">
                    <a
                      href="https://upstox.com/become-sub-broker/"
                      className="nav-link"
                    >
                      <Text size="large" color="text.1">
                        Partner with us
                      </Text>
                    </a>
                  </li>
                  <li className="product-tools no-hover-border">
                    <a
                      href="https://upstox.com/brokerage-charges/"
                      className="nav-link"
                    >
                      <Text size="large" color="text.1">
                        Pricing
                      </Text>
                    </a>
                  </li>
                </ul>
              </nav>
            </View>

            {/* <View alignItems="center">
              {showSearchBox && <SearchBox />}
              <div className="nav-btn-link">haneef</div>
              <ul style={{ display: 'flex', gap: '16px' }}>
                <li className="nav-btn-link no-hover-border">
                  <a target="_blank" href={onboardingUrl}>
                    Open a Demat Account
                  </a>
                </li>

                <li className="nav-btn-link-default no-hover-border">
                  <a target="_blank" href={getSignInUrl()}>
                    Sign In
                  </a>
                </li>
              </ul>
            </View> */}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '16px',
              }}
            >
              {showSearchBox && (
                <div className="scripSearch">
                  <NavScripSearchInput hover={clas === 'result_hover'} />
                </div>
              )}

              <nav className={'links'}>
                <ul style={{ display: 'flex', gap: '16px' }}>
                  <li className="nav-btn-link no-hover-border">
                    <a target="_blank" href={onboardingUrl}>
                      Open Demat Account
                    </a>
                  </li>
                  <li className="nav-btn-link-default no-hover-border">
                    <a target="_blank" href={getSignInUrl()}>
                      Sign In
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </View>
        </View>
      </View>
    </Fragment>
  );
};

export default Header;
