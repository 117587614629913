import { ROUTES } from './constants';
import queryString from 'query-string';

const getUtm = () => {
  const query = queryString.parse(location.search);
  let source = '';
  const utmData = {
    gclid: '',
    utm_adgroup: '',
    utm_campaign: '',
    utm_device: '',
    utm_matchtype: '',
    utm_medium: '',
    utm_source: '',
    utm_term: '',
  };

  if (query) {
    utmData.gclid = query.gclid as string;
    utmData.utm_adgroup = query.utm_adgroup as string;
    utmData.utm_campaign = query.utm_campaign as string;
    utmData.utm_device = query.utm_device as string;
    utmData.utm_matchtype = query.utm_matchtype as string;
    utmData.utm_medium = query.utm_medium as string;
    utmData.utm_source = query.utm_source as string;
    utmData.utm_term = query.utm_term as string;
    for (const [key, value] of Object.entries(utmData)) {
      if (value) {
        source = `${source}&${key}=${value}`;
      }
    }
  }
  return source.slice(1);
};

export const getOnboardingUrl = () => {
  let url = ROUTES.ONBOARDING_ROUTE;
  const utmData = getUtm();
  if (utmData) {
    url = `${url}&${getUtm()}`;
  }
  return url;
};

const getCurrentSearchParams = () => {
  if (typeof window !== 'undefined' && window.location?.search) {
    const searchParams = new URLSearchParams(location.search);

    // Remove the 'landing_page' query parameter if it exists
    if (searchParams.has('landing_page')) {
      searchParams.delete('landing_page');
    }

    if (searchParams.has('f')) {
      searchParams.append('referral_code', searchParams.get('f'));
      searchParams.delete('f');
    }

    searchParams.append('client_id', process.env.CLIENT_ID);
    searchParams.append('redirect_uri', process.env.LOGIN_REDIRECT_URL);

    return `?${searchParams.toString()?.replace(/%2F/g, '/')}`;
  }
};

export const getSignInUrl = () => {
  let url = new URL(process.env.LOGIN_URL);

  return url.toString()?.replace(/%2F/g, '/');
};
