import { SEARCH_URLS } from './constants';
import {
  ScripItem,
  SearchApiResponse,
  Segments,
  TrendingApiResponse,
} from './Types';

import HttpRequestHandler from '../../common/services/httpRequestHandler';

let trendingCache: TrendingApiResponse['data'] | null = null;
const { TRENDING_API_URL, SEARCH_API_URL, SOCIAL_REACTION_COUNTER } =
  SEARCH_URLS;

export const getTrendingSearchResults = async () => {
  try {
    if (!trendingCache) {
      const response: any = await HttpRequestHandler.get<TrendingApiResponse>(
        TRENDING_API_URL,
        process.env.SERVICE_URL,
      );
      if (!response) {
        throw 'Data not found';
      }
      trendingCache = response.data as TrendingApiResponse['data'];
    }

    return trendingCache;
  } catch (error) {}
};

export const getSearchResults = async (query: string, segments: string) => {
  try {
    const response: any = await HttpRequestHandler.get<SearchApiResponse>(
      `${SEARCH_API_URL}query=${query}&segments=${segments}&records=15&pageNumber=1`,
      process.env.SERVICE_URL,
    );
    if (!response) {
      throw 'Data not found';
    }
    return response.data;
  } catch (error) {}
};

export async function incrementTrendingCount(scrip: ScripItem) {
  const { instrumentKey, segment, upstoxSchemeId } = scrip;

  const payload: {
    data: {
      [key in string]: string;
    };
  } = {
    data: {
      instrumentKey,
      segment,
    },
  };

  if (scrip.segment === Segments.MF) {
    payload['data']['upstoxSchemeId'] = upstoxSchemeId;
  }

  try {
    const response = await HttpRequestHandler.post<{ success: boolean }>(
      SOCIAL_REACTION_COUNTER,
      payload,
      process.env.SERVICE_URL,
    );
    if (!response.success) {
      throw new Error('Request failed');
    }
    return { success: response.data.success };
  } catch (error) {}
}
