import React, { useState } from 'react';
import { Text, View } from '@upstox/upstox-ui';
import { stockLinksSectionData } from '../constants';
import Collapse from '@ui-ipo-common/components/Collapse';
import { GTM_EVENTS } from 'src/constants';
import { registerGTMEvent } from 'utils/events';

const StockLinksSectionMobile: React.FC = () => {
  return (
    <View flexDirection="column" className="faqWrapper">
      <View flexDirection="column">
        {stockLinksSectionData.map((link, index) => (
          <Collapse
            title={link.label}
            key={link.key}
            className="collapseContainer"
            headerClassName="f-header"
            bodyClassName="body"
            iconType="arrow"
            iconColor="white"
          >
            <div className="footer-stock-links-mobile">
              {link?.links?.map((subLink) => (
                <Text
                  key={subLink?.key}
                  onClick={() => {
                    registerGTMEvent({
                      event: GTM_EVENTS.FOOTER_INTERACTION,
                      cta_text: subLink.label,
                      header_text: link.label,
                      section_name: 'NA',
                    });
                  }}
                >
                  <a
                    href={subLink?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-stock-link-mobile"
                  >
                    {subLink?.label}
                  </a>
                </Text>
              ))}
            </div>
          </Collapse>
        ))}
      </View>
    </View>
  );
};

export default StockLinksSectionMobile;
