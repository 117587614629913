import {
  ScripItem,
  TrendingApiResponseKeys,
  optionChainInstrumentType,
} from './Types';

import {
  filterSearchResults,
  getListDisplayItems,
  isEquity,
  isIndex,
  createScripPageUrl,
  getSegmentFromKey,
} from './utils';

import { MIN_CHAR_COUNT, SEARCH_TABS } from './constants';

import styles from './styles.module.scss';

import cx from 'clsx';
import {
  getSearchResults,
  getTrendingSearchResults,
  incrementTrendingCount,
} from './api';
// import CustomIcon from 'src/ui-library/components/Icon';
import { Autocomplete } from '../Autocomplete';
import { GTM_EVENTS } from 'src/constants';
import { registerGTMEvent } from 'utils/events';

interface Props {
  show?: boolean;
  onClose?: () => void;
  hover?: boolean;
}

const ScripDropdownLoader = () => (
  <>
    {Array(4)
      .fill('loader')
      .map((item, i) => (
        <div
          key={`${item}-${i}`}
          style={{ display: 'flex', flexDirection: 'column', padding: '12px' }}
        >
          <span
            className={cx(
              styles.searchSkeleton,
              styles.loaderItem,
              styles.skeletonMb1,
            )}
          ></span>
          <span
            className={cx(
              styles.searchSkeleton,
              styles.loaderItem,
              styles.skeletonH4,
            )}
          ></span>
        </div>
      ))}
  </>
);

export const NavScripSearchInput: React.FC<Props> = ({
  show,
  onClose,
  hover,
}) => {
  return (
    <div className={show && styles.abs}>
      <Autocomplete<
        ScripItem,
        {
          displayName: string;
          key: string;
        }
      >
        hover={hover}
        placeholder="Search any stock, MF (E.g: AXIS BANK)"
        renderLoader={() => <ScripDropdownLoader />}
        minCharRequired={MIN_CHAR_COUNT}
        debounceTimeInMillisecs={300}
        iconPosition="start"
        tabs={SEARCH_TABS}
        defaultDataSource={async (setItems, setLoader, activeTab) => {
          const data = await getTrendingSearchResults();
          if (data) {
            setItems(
              filterSearchResults(
                data[activeTab.key as TrendingApiResponseKeys],
              ),
            );
          }
          setLoader(false);
        }}
        dataSource={async (searchTerm, setItems, setLoader, activeTab) => {
          const data = await getSearchResults(
            searchTerm,
            getSegmentFromKey(activeTab.key),
          );
          if (data) {
            setItems(
              filterSearchResults(
                data.searchList.map((item: any) => item.attributes),
              ),
            );
          }
          setLoader(false);
        }}
        renderItem={(item, activeTab) => {
          const { displayName, displaySymbol, displayType } =
            getListDisplayItems(item);

          return (
            <div className={styles.searchResultItem}>
              <div className={styles.resultInfo}>
                <div
                  className={`${styles.displayName} ${
                    isEquity(item) || isIndex(item)
                      ? `${styles.capitalize}`
                      : ''
                  }`}
                >
                  {displayName}
                </div>
                <div
                  className={`${styles.displaySymbol} ${
                    item.segment === 'MF' ? `${styles.capitalize}` : ''
                  }`}
                >
                  {displaySymbol}
                </div>
              </div>

              <span className={styles.displayType}>{`${
                activeTab.key === 'ALL' ? displayType : ''
              }`}</span>
            </div>
          );
        }}
        renderDropdown={(searchTerm, items, renderItem, activeTab) => {
          if (!items?.length) {
            return (
              <div className={styles.noResultsFound}>No results found.</div>
            );
          }

          return (
            <>
              {searchTerm.length < MIN_CHAR_COUNT && (
                <div className={styles.trending}>Trending</div>
              )}
              <div>
                <ul className={styles.searchResultDropdownItem}>
                  {items.map((item, i) => (
                    <li
                      key={i}
                      onClick={(e) => {
                        registerGTMEvent({
                          event: GTM_EVENTS.SEARCH_INTERACTION,
                          search_type: 'Search result',
                          cta_text:
                            getListDisplayItems(
                              item,
                            )?.displayName?.toUpperCase() || '',
                          section_name: 'NA',
                        });
                        const url = createScripPageUrl(item);
                        if (
                          searchTerm.length >= MIN_CHAR_COUNT &&
                          item?.instrumentType !== optionChainInstrumentType
                        ) {
                          incrementTrendingCount(item)
                            .then((res) => {
                              return res;
                            })
                            .catch((err) => {
                              console.error(err);
                            })
                            .finally(() => {
                              window.open(url, '_self');
                            });
                        } else {
                          window.open(url, '_self');
                        }
                      }}
                    >
                      {renderItem(item, activeTab)}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          );
        }}
        onTabClicked={() => {}}
        // renderDropdown={(props) => div}
      />
      {
        show && <>close</>
        // (
        //   <CustomIcon
        //     iconName="close"
        //     onClick={onClose}
        //     size="large"
        //     className="ml10"
        //   />
        // )
      }
    </div>
  );
};
