import React, { useEffect, useState } from 'react';
import StockLinksSectionDesktop from './StockLinksSectionDesktop/StockLinksSectionDesktop';
import StockLinksSectionMobile from './StockLinksSectionMobile/StockLinksSectionMobile';
import { useWindowSize } from 'src/common/hooks/useWindowSize';

export const StockLinksSection: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width < 991;

  return (
    <div>
      {!isMobile && <StockLinksSectionDesktop />}
      {isMobile && <StockLinksSectionMobile />}
    </div>
  );
};

export default StockLinksSection;
