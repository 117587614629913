import React, { FunctionComponent, useState } from 'react';
import useCurrentWitdh from '../../common/hooks/useCurrentWidth';
import Copyright from './Copyright';
import AlphabeticLinks from './AlphabeticLinks';
import { STOCKS_ALPHABATIC_BASE_URL } from '../Models/Actions/Footer/url-helper';
import StockLinksSection from './StockLinksSection/StockLinksSection';
import Address from './Address';
import SocialMedia from './SocialMedia';
import QRScanner from './QRScanner';
import ProductAndServices from './ProductAndServices';
import ReadMoreText from './ReadMoreText';

type Props = {
  wrapperClass?: string;
};

const Footer: FunctionComponent<Props> = ({ wrapperClass }) => {
  // -1 specifies no tab open
  const [tab1Id, setTab1Id] = useState(-1);
  const [tab2Id, setTab2Id] = useState(-1);

  const handleTabChange = (id: number, tab: number) => {
    // if tab itself open close it
    // else open it
    if (tab === 0) {
      setTab2Id(-1);
      if (tab1Id === id) {
        setTab1Id(-1);
      } else {
        setTab1Id(id);
      }
    } else if (tab === 1) {
      setTab1Id(-1);
      if (tab2Id === id) {
        setTab2Id(-1);
      } else {
        setTab2Id(id);
      }
    }
  };

  const screenWidth = useCurrentWitdh();

  return (
    <footer className={`footer ${wrapperClass}`}>
      <div className="footer-upstox-info">
        <div>
          <Address />
          <SocialMedia />
        </div>
        <QRScanner />
      </div>
      <StockLinksSection />
      <AlphabeticLinks title={'Stocks'} baseUrl={STOCKS_ALPHABATIC_BASE_URL} />
      <ProductAndServices />
      <ReadMoreText />
      <Copyright />
    </footer>
  );
};

export default Footer;
