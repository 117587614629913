import React, { useState } from 'react';
import { linkData } from '../InfoLinks/InfoLinks';
import styles from './styles.module.scss';
import { GTM_EVENTS } from 'src/constants';
import { registerGTMEvent } from 'utils/events';

interface DoubleColLinksProps {
  links: linkData[];
  title?: string;
}

const DoubleColLinks = ({ links, title }: DoubleColLinksProps) => {
  const tempArr = links.slice();
  const midpoint = Math.ceil(tempArr.length / 2);
  const firstGroup = tempArr.slice(0, midpoint);
  const secondGroup = tempArr.slice(midpoint);

  const arr = [firstGroup, secondGroup];
  return (
    <div style={{ display: 'flex', gap: '35px', width: '100%' }}>
      {arr.map((link, i) => {
        return (
          <div key={i} className={styles.linkGroup}>
            {link.map((item, j) => {
              return (
                <a
                  key={j}
                  href={item.link}
                  className={styles.link}
                  onClick={() => {
                    registerGTMEvent({
                      event: GTM_EVENTS.FOOTER_INTERACTION,
                      cta_text: item.text,
                      header_text: title,
                      section_name: 'NA',
                    });
                  }}
                >
                  {item.text}
                </a>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default DoubleColLinks;
