import { spacing, color } from '@upstox/upstox-ui';

export const container: CSSFunction = ({ theme }) => ({
  backgroundColor: color(theme, 'background.default'),
  height: '100%',
  overflowY: 'auto',
});

export const wrapper: CSSFunction = ({ theme }) => ({
  position: 'relative',
  padding: spacing(theme, [0, 'medium']),
  gap: 0,
  flexWrap: 'wrap',

  '@media (min-width: 1024px)': {
    gap: '48px',
    flexWrap: 'nowrap',
  },

  '@media (min-width: 1200px)': {
    padding: spacing(theme, [0, 'xxxlarge']),
  },
});

export const main: CSSFunction = ({ theme }) => ({
  width: '100%',

  '@media (min-width: 1024px)': {
    width: '60%',
  },

  '@media (min-width: 1200px)': {
    width: '70% !important',
  },
});

export const footer: CSSFunction = ({
  theme,
  cssProps: { footerBtmSpacing },
}) => ({
  backgroundColor: '#37135B',
  paddingTop: '64px',
  paddingBottom: '64px',
  marginTop: spacing(theme, 'xxlarge'),
  marginBottom: footerBtmSpacing ? '80px' : 0,

  '& footer': {
    maxWidth: '1126px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display:"flex",
    flexDirection:"column",
    gap:'56px',

    '@media (max-width: 1140px)': {
      paddingLeft: spacing(theme, 'xlarge'),
      paddingRight: spacing(theme, 'xlarge'),
    },

    '@media (max-width: 640px)': {
      gap:'64px'
    },
  },
});
