import {
  FACEBOOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
  INSTAGRAM_URL,
  TELEGRAM_URL,
  LINKEDIN_URL,
  WHATSAPP_URL,
} from '../../Models/Actions/Footer/url-helper';

const socialMediaData = [
  {
    link: FACEBOOK_URL,
    iconPath: '/ipo-initial-public-offering/facebook.svg',
    alt: 'facebook',
    name: 'facebook',
  },
  {
    link: TWITTER_URL,
    iconPath: '/ipo-initial-public-offering/twitter.svg',
    alt: 'Twitter',
    name: 'twitter',
  },
  {
    link: TELEGRAM_URL,
    iconPath: '/ipo-initial-public-offering/telegram.svg',
    alt: 'Telegram',
    name: 'telegram',
  },

  {
    link: LINKEDIN_URL,
    iconPath: '/ipo-initial-public-offering/linkedin.svg',
    alt: 'Linkedin',
    name: 'linkedin',
  },
  {
    link: YOUTUBE_URL,
    iconPath: '/ipo-initial-public-offering/youtube.svg',
    alt: 'Youtube',
    name: 'youtube',
  },

  {
    link: INSTAGRAM_URL,
    iconPath: '/ipo-initial-public-offering/insta.svg',
    alt: 'instagram',
    name: 'instagram',
  },
  {
    link: WHATSAPP_URL,
    iconPath: '/ipo-initial-public-offering/whatsapp.svg',
    alt: 'whatsapp',
    name: 'whatsapp',
  },
];

export const socialHandles = socialMediaData.map(
  ({ link, iconPath, alt, name }) => ({
    link,
    icon: (
      <img src={iconPath} width="24" height="24" loading="lazy" alt={alt} />
    ),
    name,
  }),
);
